import { render, staticRenderFns } from "./SlugHero.vue?vue&type=template&id=75d082f1&scoped=true&"
import script from "./SlugHero.vue?vue&type=script&lang=js&"
export * from "./SlugHero.vue?vue&type=script&lang=js&"
import style0 from "./SlugHero.vue?vue&type=style&index=0&id=75d082f1&prod&lang=scss&scoped=true&"
import style1 from "./SlugHero.vue?vue&type=style&index=1&id=75d082f1&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75d082f1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BreadCrumbs: require('/vercel/path0/components/ui/BreadCrumbs.vue').default,AuthorWithDate: require('/vercel/path0/components/journal/AuthorWithDate.vue').default,HtmlText: require('/vercel/path0/components/componentloader/HtmlText.vue').default,ArrowLink: require('/vercel/path0/components/ui/ArrowLink.vue').default,AppLink: require('/vercel/path0/components/ui/AppLink.vue').default,TagIcon: require('/vercel/path0/components/icons/TagIcon.vue').default,ShareBlogpost: require('/vercel/path0/components/ShareBlogpost.vue').default,Play: require('/vercel/path0/components/icons/Play.vue').default,Container: require('/vercel/path0/components/ui/Container.vue').default,SocialMediaButtons: require('/vercel/path0/components/ui/SocialMediaButtons.vue').default,YoutubeModal: require('/vercel/path0/components/componentloader/YoutubeModal.vue').default})
